<template>
  <v-list
    expand
    nav
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-group>
      <template v-for="(item, i) in items">
        <default-list-group
          v-if="item.items"
          :key="`group-${i}`"
          :item="item"
        />
        <v-divider
          v-else-if="item.divider"
          :key="i"
          class="mx-3 mb-2"
        />
        <default-list-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: 'DefaultList',
    components: {
      DefaultListGroup: () => import('./ListGroup'),
      DefaultListItem: () => import('./ListItem'),
    },
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>
